define("ember-table-it/components/table-it", ["exports", "ember"], function (exports, _ember) {
  "use strict";

  exports["default"] = _ember["default"].Component.extend({
    totalPages: (function () {
      return this.get("rows").get("content.meta.total_pages");
    }).property("rows.content"),
    actions: {
      setPage: function setPage(current) {
        this.sendAction("action", current);
      }
    }
  });
});