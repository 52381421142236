define("ember-bootstrap/templates/components/bs-tab", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["wrong-type"]
          },
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 3,
              "column": 0
            }
          },
          "moduleName": "modules/ember-bootstrap/templates/components/bs-tab.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "yield", [["get", "activeId", ["loc", [null, [2, 10], [2, 18]]]], ["subexpr", "action", ["select"], [], ["loc", [null, [2, 19], [2, 36]]]]], [], ["loc", [null, [2, 2], [2, 38]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.4.6",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 8,
                        "column": 10
                      },
                      "end": {
                        "line": 8,
                        "column": 80
                      }
                    },
                    "moduleName": "modules/ember-bootstrap/templates/components/bs-tab.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1, "class", "caret");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    return morphs;
                  },
                  statements: [["content", "item.groupTitle", ["loc", [null, [8, 33], [8, 52]]]]],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      "fragmentReason": false,
                      "revision": "Ember@2.4.6",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 10,
                          "column": 12
                        },
                        "end": {
                          "line": 12,
                          "column": 12
                        }
                      },
                      "moduleName": "modules/ember-bootstrap/templates/components/bs-tab.hbs"
                    },
                    isEmpty: false,
                    arity: 1,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("              ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("li");
                      var el2 = dom.createElement("a");
                      dom.setAttribute(el2, "role", "tab");
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element1 = dom.childAt(fragment, [1]);
                      var element2 = dom.childAt(element1, [0]);
                      var morphs = new Array(4);
                      morphs[0] = dom.createAttrMorph(element1, 'class');
                      morphs[1] = dom.createAttrMorph(element2, 'href');
                      morphs[2] = dom.createElementMorph(element2);
                      morphs[3] = dom.createMorphAt(element2, 0, 0);
                      return morphs;
                    },
                    statements: [["attribute", "class", ["concat", [["subexpr", "if", [["subexpr", "bs-eq", [["get", "activeId", ["loc", [null, [11, 37], [11, 45]]]], ["get", "subItem.elementId", ["loc", [null, [11, 46], [11, 63]]]]], [], ["loc", [null, [11, 30], [11, 64]]]], "active"], [], ["loc", [null, [11, 25], [11, 75]]]]]]], ["attribute", "href", ["concat", ["#", ["get", "subItem.elementId", ["loc", [null, [11, 89], [11, 106]]]]]]], ["element", "action", ["select", ["get", "subItem.elementId", ["loc", [null, [11, 139], [11, 156]]]]], [], ["loc", [null, [11, 121], [11, 158]]]], ["content", "subItem.title", ["loc", [null, [11, 159], [11, 176]]]]],
                    locals: ["subItem"],
                    templates: []
                  };
                })();
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.4.6",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 9,
                        "column": 10
                      },
                      "end": {
                        "line": 13,
                        "column": 10
                      }
                    },
                    "moduleName": "modules/ember-bootstrap/templates/components/bs-tab.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "each", [["get", "item.children", ["loc", [null, [10, 20], [10, 33]]]]], [], 0, null, ["loc", [null, [10, 12], [12, 21]]]]],
                  locals: [],
                  templates: [child0]
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.6",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 7,
                      "column": 8
                    },
                    "end": {
                      "line": 14,
                      "column": 8
                    }
                  },
                  "moduleName": "modules/ember-bootstrap/templates/components/bs-tab.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "bs-dropdown-toggle", [], [], 0, null, ["loc", [null, [8, 10], [8, 103]]]], ["block", "bs-dropdown-menu", [], [], 1, null, ["loc", [null, [9, 10], [13, 31]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 6,
                    "column": 6
                  },
                  "end": {
                    "line": 15,
                    "column": 6
                  }
                },
                "moduleName": "modules/ember-bootstrap/templates/components/bs-tab.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "bs-dropdown", [], ["tagName", "li", "class", ["subexpr", "if", [["subexpr", "bs-contains", [["get", "item.childIds", ["loc", [null, [7, 59], [7, 72]]]], ["get", "activeId", ["loc", [null, [7, 73], [7, 81]]]]], [], ["loc", [null, [7, 46], [7, 82]]]], "active"], [], ["loc", [null, [7, 42], [7, 92]]]]], 0, null, ["loc", [null, [7, 8], [14, 24]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.6",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 16,
                      "column": 8
                    },
                    "end": {
                      "line": 16,
                      "column": 157
                    }
                  },
                  "moduleName": "modules/ember-bootstrap/templates/components/bs-tab.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1, "role", "tab");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [0]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createAttrMorph(element0, 'href');
                  morphs[1] = dom.createElementMorph(element0);
                  morphs[2] = dom.createMorphAt(element0, 0, 0);
                  return morphs;
                },
                statements: [["attribute", "href", ["concat", ["#", ["get", "item.elementId", ["loc", [null, [16, 75], [16, 89]]]]]]], ["element", "action", ["select", ["get", "item.elementId", ["loc", [null, [16, 122], [16, 136]]]]], [], ["loc", [null, [16, 104], [16, 138]]]], ["content", "item.title", ["loc", [null, [16, 139], [16, 153]]]]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 15,
                    "column": 6
                  },
                  "end": {
                    "line": 17,
                    "column": 6
                  }
                },
                "moduleName": "modules/ember-bootstrap/templates/components/bs-tab.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["block", "bs-nav-item", [], ["active", ["subexpr", "bs-eq", [["get", "item.elementId", ["loc", [null, [16, 37], [16, 51]]]], ["get", "activeId", ["loc", [null, [16, 52], [16, 60]]]]], [], ["loc", [null, [16, 30], [16, 61]]]]], 0, null, ["loc", [null, [16, 8], [16, 173]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 5,
                  "column": 4
                },
                "end": {
                  "line": 18,
                  "column": 4
                }
              },
              "moduleName": "modules/ember-bootstrap/templates/components/bs-tab.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "item.isGroup", ["loc", [null, [6, 12], [6, 24]]]]], [], 0, 1, ["loc", [null, [6, 6], [17, 13]]]]],
            locals: ["item"],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 2
              },
              "end": {
                "line": 19,
                "column": 2
              }
            },
            "moduleName": "modules/ember-bootstrap/templates/components/bs-tab.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "each", [["get", "navItems", ["loc", [null, [5, 12], [5, 20]]]]], [], 0, null, ["loc", [null, [5, 4], [18, 13]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 0
            },
            "end": {
              "line": 24,
              "column": 0
            }
          },
          "moduleName": "modules/ember-bootstrap/templates/components/bs-tab.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "tab-content");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "bs-nav", [], ["type", ["subexpr", "@mut", [["get", "type", ["loc", [null, [4, 17], [4, 21]]]]], [], []]], 0, null, ["loc", [null, [4, 2], [19, 13]]]], ["inline", "yield", [["get", "activeId", ["loc", [null, [22, 12], [22, 20]]]], ["subexpr", "action", ["select"], [], ["loc", [null, [22, 21], [22, 38]]]]], [], ["loc", [null, [22, 4], [22, 40]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 24,
            "column": 7
          }
        },
        "moduleName": "modules/ember-bootstrap/templates/components/bs-tab.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "customTabs", ["loc", [null, [1, 6], [1, 16]]]]], [], 0, 1, ["loc", [null, [1, 0], [24, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});