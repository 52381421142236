define("ember-paper/templates/components/paper-form", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 23,
            "column": 0
          }
        },
        "moduleName": "modules/ember-paper/templates/components/paper-form.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "yield", [["subexpr", "hash", [], ["isValid", ["get", "isValid", ["loc", [null, [2, 10], [2, 17]]]], "isInvalid", ["get", "isInvalid", ["loc", [null, [3, 12], [3, 21]]]], "isTouched", ["get", "isTouched", ["loc", [null, [4, 12], [4, 21]]]], "isInvalidAndTouched", ["get", "isInvalidAndTouched", ["loc", [null, [5, 22], [5, 41]]]], "input", ["subexpr", "component", [["get", "inputComponent", ["loc", [null, [6, 19], [6, 33]]]]], ["parentComponent", ["subexpr", "@mut", [["get", "this", ["loc", [null, [7, 20], [7, 24]]]]], [], []], "onValidityChange", ["subexpr", "action", ["onValidityChange"], [], ["loc", [null, [8, 21], [8, 48]]]]], ["loc", [null, [6, 8], [9, 3]]]], "submit-button", ["subexpr", "component", [["get", "submitButtonComponent", ["loc", [null, [10, 27], [10, 48]]]]], ["type", "submit"], ["loc", [null, [10, 16], [12, 3]]]], "select", ["subexpr", "component", [["get", "selectComponent", ["loc", [null, [13, 20], [13, 35]]]]], ["parentComponent", ["subexpr", "@mut", [["get", "this", ["loc", [null, [14, 19], [14, 23]]]]], [], []], "onValidityChange", ["subexpr", "action", ["onValidityChange"], [], ["loc", [null, [15, 20], [15, 47]]]]], ["loc", [null, [13, 9], [16, 3]]]], "autocomplete", ["subexpr", "component", [["get", "autocompleteComponent", ["loc", [null, [17, 26], [17, 47]]]]], ["parentComponent", ["subexpr", "@mut", [["get", "this", ["loc", [null, [18, 19], [18, 23]]]]], [], []], "onValidityChange", ["subexpr", "action", ["onValidityChange"], [], ["loc", [null, [19, 20], [19, 47]]]]], ["loc", [null, [17, 15], [20, 3]]]], "onSubmit", ["subexpr", "action", ["onSubmit"], [], ["loc", [null, [21, 11], [21, 30]]]]], ["loc", [null, [1, 8], [22, 1]]]]], [], ["loc", [null, [1, 0], [22, 3]]]]],
      locals: [],
      templates: []
    };
  })());
});